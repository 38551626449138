import { track } from '@vercel/analytics/react';
import axios from 'axios';
import { useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useAccount, useQuery } from 'wagmi';
import { getLineChartOptions } from '../../constants/chart';
import { IVault, VaultAnalyticsTvlDataResponse } from '../../types';
import { URL } from '../../utils/api';
import { formatRelativeTime } from '../../utils/n-formatter';
import { isLstVault, isStableVault } from '../../utils/vault';
import { Image } from '../Image';

interface TvlChartProps {
  vault: IVault;
  vaultType: 'v1' | 'v2' | 'palm';
}

enum Section {
  TVL = 'TVL',
  COMPOSITION = 'COMPOSITION',
  ABOUT = 'ABOUT',
  RISKS = 'RISKS',
}

const Chart = ({ options, series }: any) => {
  return <ReactApexChart options={options} series={series} height={380} type="area" />;
};

const MemoizedChart = Chart;

const TvlChart = ({ vault, vaultType }: TvlChartProps) => {
  const [activeSection, setActiveSection] = useState<Section>(Section.COMPOSITION);

  const { address: ownAddress } = useAccount();

  const {
    data: tvlData,
    isLoading: isLoadingTvlData,
    isError,
  } = useQuery(
    ['vault-tvl', vault.id],
    async () => {
      const res = await axios.get<VaultAnalyticsTvlDataResponse>(`${URL}/api/analytics/v2/tvl/${vault.id}`);
      return res.data;
    },
    {
      select: (data) => {
        const positions0: [string, number][] = [];
        const positions1: [string, number][] = [];

        for (let i = 0; i < data.data.history.length; i++) {
          const point = data.data.history[i];
          const timestamp = point.timeAt;
          const position0 = point.value0USD;
          const position1 = point.value1USD;

          positions0.push([timestamp, position0]);
          positions1.push([timestamp, position1]);
        }

        const seriesDataComposition = [
          {
            name: 'Position 0',
            data: positions0,
          },
          {
            name: 'Position 1',
            data: positions1,
          },
        ];

        if (seriesDataComposition[0].data.length === 1) {
          const lastPoint0 = seriesDataComposition[0].data[0];

          const newPoint0: [string, number] = [
            new Date(new Date(lastPoint0[0]).getTime() - 10 * 60 * 1000).toString(),
            lastPoint0[1],
          ];

          seriesDataComposition[0].data.unshift(newPoint0);

          const lastPoint1 = seriesDataComposition[1].data[0];

          const newPoint1: [string, number] = [
            new Date(new Date(lastPoint0[0]).getTime() - 10 * 2 * 60 * 1000).toString(),
            lastPoint1[1],
          ];
          seriesDataComposition[1].data.unshift(newPoint1);
        }

        return {
          history: seriesDataComposition,
          //  Tvl: seriesDataTvl,
        };
      },
    },
  );

  return (
    <div>
      {vaultType === 'v2' && (
        <div className="flex items-center flex-wrap gap-2 mb-6">
          <button
            onClick={() => {
              track('click active section composition', {
                feature: 'vault tvl chart',
                type: 'click',
                wallet: ownAddress as string,
                vaultId: vault.id,
              });
              setActiveSection(Section.COMPOSITION);
            }}
            className={`border  rounded-[16px] px-[12px] py-[6px]  ${
              activeSection === Section.COMPOSITION
                ? 'bg-vault-light-gray text-vault-black-primary border border-vault-light.gray'
                : 'border-vault-gray-hover text-vault-gray-hover hover:text-vault-white hover:border-vault-white duration-300'
            }`}
          >
            Composition
          </button>

          <button
            onClick={() => {
              track('click active section about', {
                feature: 'vault tvl chart',
                type: 'click',
                wallet: ownAddress as string,
                vaultId: vault.id,
              });

              setActiveSection(Section.ABOUT);
            }}
            className={`border  rounded-[16px] px-[12px] py-[6px]  ${
              activeSection === Section.ABOUT
                ? 'bg-vault-light-gray text-vault-black-primary border border-vault-light.gray'
                : 'border-vault-gray-hover text-vault-gray-hover hover:text-vault-white hover:border-vault-white duration-300'
            }`}
          >
            About
          </button>

          <button
            onClick={() => {
              track('click active section risks', {
                feature: 'vault tvl chart',
                type: 'click',
                wallet: ownAddress as string,
                vaultId: vault.id,
              });

              setActiveSection(Section.RISKS);
            }}
            className={`border  rounded-[16px] px-[12px] py-[6px]  ${
              activeSection === Section.RISKS
                ? 'bg-vault-light-gray text-vault-black-primary border border-vault-light.gray'
                : 'border-vault-gray-hover text-vault-gray-hover hover:text-vault-white hover:border-vault-white duration-300'
            }`}
          >
            Risks
          </button>
        </div>
      )}
      {isLoadingTvlData && (
        <div className="flex flex-col mx-2 gap-4">
          <Skeleton
            style={{ borderRadius: '12px' }}
            height="288px"
            width="655px"
            baseColor="#292828"
            highlightColor="#6e6d6d"
            duration={0.9}
          />
        </div>
      )}
      {activeSection === Section.COMPOSITION && tvlData && !isLoadingTvlData && (
        <div>
          <MemoizedChart options={getLineChartOptions(tvlData.history, vault)} series={tvlData.history} />
          <div className="flex items-center justify-between text-[14px] align-middle">
            <div className="flex items-center gap-5">
              <div className="text-vault-orange flex items-center gap-2">
                <div className="w-[14px] h-[14px] rounded-full bg-vault-orange -mt-[1px]"></div>
                {vault.symbol0?.toUpperCase()} Holdings
              </div>
              <div className="text-[#F3F2F2] flex items-center gap-2">
                <div className="w-[14px] h-[14px] rounded-full bg-[#F3F2F2] -mt-[1px]"></div>
                {vault.symbol1?.toUpperCase()} Holdings
              </div>
            </div>
            {tvlData?.history[0]?.data?.[0]?.[0] && (
              <div className="text-vault-gray">
                Last updated:{' '}
                {formatRelativeTime(tvlData.history[0].data[tvlData.history[0].data.length - 1][0].toString())}
              </div>
            )}
          </div>
        </div>
      )}

      {activeSection === Section.ABOUT && (
        <>
          <p className="text-[16px] leading-[20px] tracking-[-0.16px]">
            This is a {isLstVault(vault.symbol0, vault.symbol1) ? 'LST ' : ''}{' '}
            {isStableVault(vault.symbol0, vault.symbol1) ? 'stable' : 'volatile'} vault, it’s strategy is optimised to
            reduce the price impact of trades in the {vault.name} pair, as well as risk management for LPers. It
            utilises limit orders, asymmetric Tvl, and algorithmic rebalancing in order to achieve these goals.
          </p>

          <p className="mt-8 text-[16px] leading-[20px] tracking-[-0.16px]">
            The strategy is executed by Gelato Network keepers via Arrakis V2 smart contract infrastructure. The Arrakis
            V2 smart contracts underwent{' '}
            <a
              href="https://resources.arrakis.finance/resources/audits"
              target="_blank"
              rel="noreferrer"
              className="text-bg-gradient-orange"
            >
              three independent security audits
            </a>
            .
          </p>
        </>
      )}
      {activeSection === Section.RISKS && (
        <div className="flex flex-col gap-3 text-[16px] leading-[20px] tracking-[-0.16px]">
          <div className="flex items-center gap-2">
            <Image src="/assets/images/warning.svg" alt="warning" /> This strategy is unhedged, meaning you are taking
            directional risk
          </div>
          <div className="flex items-center gap-2">
            {' '}
            <Image src="/assets/images/warning.svg" alt="warning" />
            Risk of impermanent loss exists{' '}
          </div>
          <div className="flex items-center gap-2">
            {' '}
            <Image src="/assets/images/warning.svg" alt="warning" />
            Smart Contract risk exists
          </div>
        </div>
      )}
      {(isError || tvlData?.history.length === 0) && (
        <div className="w-full flex items-center justify-center h-[288px]">No historic data available</div>
      )}
    </div>
  );
};

export default TvlChart;
