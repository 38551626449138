import Banner from "../components/layout/banner/Banner";
import Footer from "../components/layout/footer/Footer";
import { Header } from "../components/layout/header";

function Disclaimer() {
  return (
    <>
      <div className="fixed w-[40%] h-[35%] top-0 orange__gradient right-0 z-0  pointer-events-none"></div>

      <Header />
      <div className="flex flex-col items-center w-full text-white">
        <div className="px-4 md:px-0 max-w-screen-lg w-full">
          <div className="bg-[#050505] w-full">
            <Banner />
          </div>
          <h1 className="text-4xl my-10">Disclaimer</h1>
          <div className="mb-20">
            <p className="mb-5">Last updated: 22nd March 2023 </p>
            <p className="mb-5">
              These terms of service, together with any documents and additional terms they incorporate by reference
              (collectively “Terms”), are entered into between Gelato Network (also referred to to as “Company” “we,”
              “us,” and “our”) and you or the legal entity that you represent (also referred to as “you” or “your”).
            </p>
            <p className="mb-5">
              {' '}
              Please read these Terms carefully as they govern your use of our site located at http://gelato.network/
              and all associated sites (the “Website”) and our Services (defined below) and describe your rights and
              obligations and our disclaimers and limitations of legal liability.
            </p>
            <p className="mb-5">
              We reserve the right to modify these Terms from time to time. If we make changes, we will notify you on
              such changes. Should we not hear from you within seven (7) working days and/or should you continue using
              our Website, then, this will be deemed an acceptance of our updated terms and conditions.
            </p>
            <p className="mb-5">
              By accessing or using the Website or Service you agree to be bound by these Terms. If you disagree with
              any part of these Terms then you may not access or use our Website or the Service.
            </p>
            <p className="mb-5">
              {' '}
              In this context, you confirm that you have also taken note of and reviewed the Privacy Policy of the
              Company.
            </p>
            <p className="mb-5">
              <h2 className="text-2xl mb-2">1. Services</h2>
              The Company offers experimental technology that is helping Web3 projects to automate transactions and
              interact with decentralized applications (the “Service”). The Website grants users access to open source
              documentation and related services, including (without limitation) resources, data and computation
              services. The Service offered by us or other participants in the Gelato Network require payment or
              otherwise involve the use of an underlying blockchain or other decentralized or permissioned
              infrastructure (“Distributed Ledger Technology”), which may require that you pay a fee, such as “gas”
              charges on e.g. the Ethereum network, for the computational resources required to perform a transaction on
              the particular Distributed Ledger Technology (such payments “Fees”). You acknowledge and agree that the
              Company has no control over any Distributed Ledger Technology transactions, the method of payment of any
              Fees, if applicable, or any actual payments of Fees, if applicable. Further, our Website or Service may
              contain links to third-party websites or services that are not owned or controlled by the Company. The
              Company has no control over, and assumes no responsibility for, the content, privacy policies, or
              practices of any third party web sites or services.{' '}
            </p>
            <p className="mb-5">
              <h2 className="text-2xl mb-2">2. Representations and Warranties </h2>
              In order to use our Website or Service you must be able to form a legally binding contract either on
              behalf of a company (or other legal entity) or as an individual and you represent and warrant that you
              have the full, right, power and authority to enter into and to comply with the obligations under these
              Terms on behalf of the legal entity that you represent. Additionally, you represent and warrant that you
              are not a citizen or resident of a state, country, territory or other jurisdiction that is embargoed by
              Switzerland (“The Country”) or where your use of the Website or the Services would be illegal or otherwise
              violate any domestic or foreign law, rule, statute, regulation, by-law, order, protocol, code, decree, or
              other directive, requirement or guideline that is published or in force (“Applicable Law”). As a condition
              to accessing or using the Services or the Website, you represent, warrant the following: <br /> (i) you
              will only use the Services and the Website for lawful purposes and in accordance with these Terms; <br />{' '}
              (ii) will ensure that all information that you provide on the Website is current, complete, and accurate;{' '}
              <br /> (iii) will maintain the security and confidentiality of access to your Distributed Ledger
              Technology Address; <br /> (iv) will identify and assess the accuracy, availability and quality of data
              that you choose to consume via the Gelato Network; <br /> (v) agree (A) that no Protected Party (defined
              below) will be responsible for any loss or damage incurred as the result of any interactions you have with
              other users of the Website, Services or Gelato Network, including the loss of any amount of tokens or
              other unit of value; and (B) if there is a dispute between you and any other site or other user, no
              Protected Party will be under any obligation to become involved. As a condition to accessing or using the
              Website or the Services, you represent, warrant and agree that you will not: <br /> (i) violate any
              Applicable Law, including, without limitation, any relevant and applicable anti-money laundering and
              anti-terrorist financing laws and any relevant and applicable privacy and data collection laws, in each
              case as may be amended; <br /> (ii) export, reexport, or transfer, directly or indirectly, any Company
              technology or Gelato Network data in violation of applicable export laws or regulations; <br /> (iii)
              infringe on or misappropriate any contract, intellectual property or other third-party right, or commit a
              tort while using the Website or the Services; <br /> (iv) misrepresent the truthfulness, sourcing or
              reliability of any content on the Website or through the Services; <br /> (v) use the Website or Services
              in any manner that could interfere with, disrupt, negatively affect, or inhibit other users from fully
              enjoying the Website or the Services, or that could damage, disable, overburden, or impair the functioning
              of the Website, Services or the Company in any manner; <br /> (vi) attempt to circumvent any content
              filtering techniques or security measures that the Company employs on the Website or the Services, or
              attempt to access any service or area of the Website or the Services that you are not authorized to
              access; <br /> (vii) use any robot, spider, crawler, scraper, or other automated means or interface not
              provided by us, to access the Website or Services or to extract data; <br /> (viii) introduce any malware,
              virus, Trojan horse, worm, logic bomb, drop-dead device, backdoor, shutdown mechanism or other harmful
              material into the Website or the Services; <br /> (ix) post content or communications on the Website or
              through the Services (including User Content (as defined below)) that are, in our sole discretion,
              libelous, defamatory, profane, obscene, pornographic, sexually explicit, indecent, lewd, vulgar,
              suggestive, harassing, hateful, threatening, offensive, discriminatory, bigoted, abusive, inflammatory,
              fraudulent or deceptive <br /> (x) post content on the Website or through the Services containing
              unsolicited promotions, political campaigning, or commercial messages or any chain messages or user
              content designed to deceive or trick the user of the Service; or <br /> (xi) encourage or induce any third
              party to engage in any of the activities prohibited under these Terms. You represent and warrant that you:{' '}
              <br /> (i) have the necessary technical expertise and ability to review and evaluate the security,
              integrity and operations of Gelato Network and its Service. <br /> (ii) have the knowledge, experience,
              understanding, professional advice and information to make your own evaluation of the merits, risks and
              applicable compliance requirements under Applicable Law of using the Website or Service. <br /> (iii)
              know, understand and accept the risks associated with your use of Gelato Network, your Distributed Ledger
              Technology Address, the Distributed Ledger Technology, and any network tokens.{' '}
            </p>
            <p className="mb-5">
              <h2 className="text-2xl mb-2">3. Proprietary Rights </h2>
              Excluding any open source software or third-party software that the Website or the Services incorporates,
              as between you and Company, the Company owns the Website and the Services, including all technology,
              content and other materials used, displayed or provided on the Website (including all intellectual
              property rights), and hereby grants you a non-exclusive, limited, revocable, non-transferable and
              non-sublicensable, license to access and use those portions of the Website and the Services that are
              proprietary to the Company in accordance with their intended uses and using their designated public
              interfaces. Any of the Company&apos;s product or service names, logos, and other marks used in the Website
              or as a part of the Services, including the Company&apos;s name and logo are trademarks owned by the
              Company or its applicable licensors. You may generally use the Company’s name and logo to refer to the
              Company’s mission and activities provided that it does not in any way suggest or imply sponsorship or
              approval by the Company. You may also indicate the relationship of your products and services to the
              Company’s mission and activities by using an accurate descriptive term in connection with your product or
              service. You may not use the Company’s name and logo in a manner that may cause confusion with others or
              result in genericization. The Company reserves its right to prohibit the use of the Company’s marks by
              anyone that we believe misuses our trademarks and/or ask for prior approval of Company before using them.
              The appearance of third party advertisements and hyperlinks on the Website does not constitute an
              endorsement, guarantee, warranty, or recommendation by us. Do conduct your own due diligence before
              deciding to use any third party services.{' '}
            </p>
            <p className="mb-5">
              <h2 className="text-2xl mb-2">4. Changes, Suspension, Termination </h2>
              Gelato Network is intended to be decentralized and self-operating, with or without any Services provided
              by the Company. Accordingly, we may, at our sole discretion, from time to time and with or without prior
              notice to you, modify, suspend or disable, temporarily or permanently, the Services offered by the
              Company, in whole or in part, for any reason whatsoever, including, but not limited to, as a result of a
              security incident. We will not be liable for any losses suffered by you resulting from any modification to
              any Services or from any suspension or termination, for any reason, of your access to all or any portion
              of the Website or the Services.
            </p>

            <p className="mb-5">
              <h2 className="text-2xl mb-2">5. Advertisements</h2>
              You hereby accept that we may send you advertisement notifications via e-mail on our products/services,
              updates or new developments from time to time (opt-in). Should you no longer wish to receive such
              notifications, you can click on the &quot;unsubscribe&quot;-button in every respective notification and
              you will no longer receive such notifications (unless notifications that are necessary for the continued
              performance of services to you).
            </p>

            <p className="mb-5">
              <h2 className="text-2xl mb-2">6. Indemnification</h2>
              You will defend, indemnify, and hold harmless the Company, our members, directors, officers, employees,
              attorneys, agents, representatives, suppliers, licensors and contractors (collectively, Protected
              Parties”) from any claim, demand, lawsuit, action, proceeding, investigation, liability, damage, loss,
              cost or expense, including without limitation reasonable attorneys’ fees, arising out of or relating to
              your use of, or conduct in connection with, the Website, Services, Gelato Network, Distributed Ledger
              Technology assets associated with your Distributed Ledger Technology Address, any other digital assets;
              your violation of these Terms; your violation of Applicable Law or regulations; or your infringement or
              misappropriation of the rights of any other person or entity. If you are obliged to indemnify any
              Protected Party, the Company (or, at its discretion, the applicable Protected Party) will have the right,
              in its sole discretion, to control any action or proceeding and to determine whether the Company wishes to
              settle, and if so, on what terms.{' '}
            </p>
            <p className="mb-5">
              <h2 className="text-2xl mb-2">7. Limitation of liability</h2>
              The Company seeks to encourage the continued growth and success of Gelato Network as a public good. The
              Company does not operate a virtual currency or derivatives exchange platform or offer trade execution or
              clearing services and therefore has no oversight, involvement, or control with respect to your
              transactions, including any token purchases and sales. You are responsible for complying with all laws and
              regulations applicable to your individual transactions with other third parties. We are not a party to
              these transactions and assume no responsibility for them. We do not guarantee that our website will be
              secure or free from bugs or viruses. You will have no claim for refunds if any bug causes funds to be
              lost. You are responsible for configuring your information technology, computer programmes and platform to
              access our website. You should use your virus protection software. You must maintain an adequate
              internet-connection with an appropriate internet service provider. We are not responsible for the
              transmission of information through telecommunication networks, this is the responsibility of your
              internet service provider. The Information on our website is provided for educational, informational, and
              entertainment purposes only, without any express or implied warranty of any kind, including warranties of
              accuracy, completeness, or fitness for any particular purpose. The Information contained in or provided
              from or through this website or our social media channels is not intended to be and does not constitute
              financial advice, investment advice, trading advice, or any other advice. The Information on this website
              and provided from or through this website is general and is not specific to you, the user or anyone else.
              You should not make any decision, financial, investment, trading or otherwise, based on any of the
              information presented on this website without undertaking independent due diligence and consultation with
              a professional broker or financial advisory. You understand that you are using any Information available
              on or through this website at your own risk. The Company makes no guarantee in regards to the
              functionality, security, or availability of the Website or Service. To the extent possible under
              applicable law, the Company shall not be responsible or liable, directly or indirectly, for any damage or
              loss caused or alleged to be caused by or in connection with the use of or reliance on any such content,
              goods or services available on or through any such web sites or services. We strongly advise you to read
              the terms and conditions and privacy policies of any third-party web sites or services that you visit.{' '}
            </p>
            <p className="mb-5">
              <h2 className="text-2xl mb-2">
                8. &quot;AS IS&quot; and &quot;AS AVAILABLE&quot;: Exclusion of Company&apos;s warranty
              </h2>
              The Service is provided to You &quot;AS IS&quot; and &quot;AS AVAILABLE&quot; and with all faults and
              defects without warranty of any kind. To the maximum extent permitted under applicable law, the Company,
              on its own behalf and on behalf of its Affiliates and its and their respective licensors and service
              providers, expressly disclaims all warranties, whether express, implied, statutory or otherwise, with
              respect to the Service, including all implied warranties of merchantability, fitness for a particular
              purpose, title and non-infringement, and warranties that may arise out of course of dealing, course of
              performance, usage or trade practice. Without limitation to the foregoing, the Company provides no
              warranty or undertaking, and makes no representation of any kind that the Service will meet your
              requirements, achieve any intended results, be compatible or work with any other software, applications,
              systems or services, operate without interruption, meet any performance or reliability standards or be
              error free or that any errors or defects can or will be corrected. Without limiting the foregoing, neither
              the Company nor any of the company&apos;s provider makes any representation or warranty of any kind,
              express or implied: <br /> (i) as to the operation or availability of the Service, or the information,
              content, and materials or products included thereon; <br /> (ii) that the Service will be uninterrupted or
              error-free; <br /> (iii) as to the accuracy, reliability, or currency of any information or content
              provided through the Service; or <br /> (iv) that the Service, its servers, the content, or e-mails sent
              from or on behalf of the Company are free of viruses, scripts, trojan horses, worms, malware, timebombs or
              other harmful components. Some jurisdictions do not allow the exclusion of certain types of warranties or
              limitations on applicable statutory rights of a consumer, so some or all of the above exclusions and
              limitations may not apply to you. But in such a case the exclusions and limitations set forth in this
              section shall be applied to the greatest extent enforceable under applicable law.{' '}
            </p>
            <p className="mb-5">
              <h2 className="text-2xl mb-2">9. Governing Law </h2>
              These terms shall be governed by the substantive laws of Switzerland without reference to any conflict of
              law statutes. The application of the United Nations Convention on Contracts for the International Sale of
              Goods shall be excluded.{' '}
            </p>
            <p className="mb-5">
              <h2 className="text-2xl mb-2">10. Dispute Resolution </h2>
              If You have any concern or dispute about the Website or Service, you agree to first try to resolve the
              dispute informally by contacting the Company. Any disputes arising out of or in connection with these
              terms and/or the Users&apos; use of our platform shall be submitted to the exclusive jurisdiction of the
              courts of the city of Zug. To the extent possible under applicable law, I confirm that I will not join or
              support so called &quot;class actions&quot; or similar collective ways of initiating litigation
              proceedings against Company.{' '}
            </p>
            <p className="mb-5">
              <h2 className="text-2xl mb-2">11. United States Legal Compliance </h2>
              You represent and warrant that <br /> (i) you are not located in a country that is subject to the United
              States government embargo, or that has been designated by the United States government as a
              &quot;terrorist supporting&quot; country, and <br /> (ii) you are not listed on any United States
              government list of prohibited or restricted parties.{' '}
            </p>
            <p className="mb-5">
              <h2 className="text-2xl mb-2">12. Severability </h2>
              If any provision of these Terms is held to be unenforceable or invalid, such provision will be interpreted
              to accomplish the objectives of such provision to the greatest extent possible under applicable law and
              the remaining provisions will continue in full force and effect.{' '}
            </p>
            <p className="mb-5">
              <h2 className="text-2xl mb-2">13. Waiver </h2>
              Except as provided herein, the failure to exercise a right or to require performance of an obligation
              under these Terms shall not affect a party&apos;s ability to exercise such right or require such
              performance at any time thereafter nor shall the waiver of a breach constitute a waiver of any subsequent
              breach.
            </p>
            <p className="mb-5"> CONTACT INFORMATION: Email: info@arrakis.finance</p>
          </div>
          <Footer />
       </div>
      
      </div>
     
    </>
  );
}

export default Disclaimer;
