import Banner from '../components/layout/banner/Banner';
import Footer from '../components/layout/footer/Footer';
import { Header } from '../components/layout/header';

function PrivacyPolicy() {
  return (
    <>
      <div className="fixed w-[40%] h-[35%] top-0 orange__gradient right-0 z-0  pointer-events-none"></div>

      <Header />
      <div className="flex flex-col items-center w-full text-white">
        <div className="px-4 md:px-0 max-w-screen-lg w-full">
          <div className="bg-[#050505] w-full flex flex-col gap-10">
            <Banner />
          </div>
          <h1 className="text-4xl my-10">Privacy Policy</h1>
          <div className="mb-20">
            <p className="mb-5">Last updated 22nd of March 2023&nbsp;</p>
            <p className="mb-5">
              This privacy notice for Spacing Guild GmbH (&quot;Company,&quot; &quot;we,&quot; &quot;us,&quot; or
              &quot;our&quot;), describes how and why we might collect, store, use, and/or share (&quot;process&quot;)
              your information when you use our services (&quot;Services&quot;), such as when you:&nbsp;
            </p>

            <li>
              Visit, use or navigate our website at https://beta.arrakis.finance/vaults, or any website of ours that
              links to this privacy notice&nbsp;
            </li>

            <li>Engage with us in other related ways, including any sales, marketing, or events</li>

            <p className="my-5">
              Questions or concerns? Reading this privacy notice will help you understand your privacy rights and
              choices. If you do not agree with our policies and practices, please do not use our Services. If you still
              have any questions or concerns, please contact us at <span>privacy@arrakis.finance.</span>
            </p>
            <p className="mb-5 mt-10 text-3xl">SUMMARY OF KEY POINTS&nbsp;</p>
            <p className="mb-5">
              <strong>
                This summary provides key points from our privacy notice, but you can find out more details about any of
                these topics by clicking the link following each key point.&nbsp;
              </strong>
            </p>
            <p className="mb-5">
              <strong>What personal information do we process? </strong>When you visit, use, or navigate our Services,
              we may process personal information depending on how you interact with the Company and the Services, the
              choices you make, and the products and features you use. Click <span>here</span>to learn more.&nbsp;
            </p>
            <p className="mb-5">
              <strong>
                Do we process any sensitive personal information (i.e. data or religious, ideological, political views,
                health, intimate sphere, racial origin, social security measures or administrative or criminal
                proceedings) ?{' '}
              </strong>
              We do not process sensitive personal information.&nbsp;
            </p>
            <p className="mb-5">
              <strong>Do we receive any information from third parties? </strong>We do not receive any information from
              third parties.&nbsp;
            </p>
            <p className="mb-5">
              <strong>How do we process your information? </strong>We process your information to provide, improve, and
              administer our Services, communicate with you, for security and fraud prevention, and to comply with law.
              We may also process your information for other purposes with your consent. We process your information
              only when we have a valid legal reason to do so. Click <span>here</span>to learn more.&nbsp;
            </p>
            <p className="mb-5">
              <strong>In what situations and with which parties do we share personal information? </strong>We may share
              information in specific situations and with specific third parties. Click <span>here</span>to learn more.
            </p>
            <p className="mb-5">
              <strong>How do we keep your information safe? </strong>We have organizational and technical&nbsp;
            </p>
            <p className="mb-5">
              processes and procedures in place to protect your personal information. However, no electronic
              transmission over the internet or information storage technology can be guaranteed to be 100% secure, so
              we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not
              be able to defeat our security and improperly collect, access, steal, or modify your information. Click{' '}
              <span>here</span>to learn more.&nbsp;
            </p>
            <p className="mb-5">
              <strong>What are your rights? </strong>Depending on where you are located geographically, the applicable
              privacy law may mean you have certain rights regarding your personal information. Click <span>here</span>
              to learn more.&nbsp;
            </p>
            <p className="mb-5">
              <strong>How do you exercise your rights? </strong>The easiest way to exercise your rights is by contacting
              us. We will consider and act upon any request in accordance with applicable data protection laws.&nbsp;
            </p>

            <h2 className="text-2xl mb-2 mt-10">1. WHAT INFORMATION DO WE COLLECT?</h2>
            <p className="mb-5">
              <span>Personal information you disclose to us&nbsp;</span>
            </p>
            <p className="mb-5">
              <strong>In Short</strong>: <em>We collect personal information that you provide to us.&nbsp;</em>
            </p>
            <p className="mb-5">
              We collect personal information that you voluntarily provide to us when you express an interest in
              obtaining information about us or our Services, when you participate in activities on the Services, or
              otherwise when you contact us.&nbsp;
            </p>
            <p className="mb-5">
              <strong>Personal Information Provided by You</strong>. The personal information that we collect depends on
              the context of your interactions with us and the Services, the choices you make, and the products and
              features you use. The personal information we collect may include the following:&nbsp;
            </p>
            <li>Names</li>
            <li>Company/Project Name</li>
            <li>Email addresses</li>
            <li>Discord handles</li>
            <li>Telegram handles</li>
            <li>Twitter handles</li>
            <li>Public wallet addresses</li>
            <li>
              <strong>Sensitive Information</strong>. We do not process sensitive information.&nbsp;
            </li>

            <p className="mb-5">
              <span>Information automatically collected&nbsp;</span>
            </p>
            <p className="mb-5">
              <strong>In Short</strong>:{' '}
              <em>
                Some information &mdash; such as your Internet Protocol (IP) address and/or browser and device
                characteristics &mdash; is collected automatically when you visit our Services.
              </em>
            </p>
            <p className="mb-5">
              We automatically collect certain information when you visit, use, or navigate the Services. This
              information does not reveal your specific identity (like your name or contact information) but may include
              device and usage information, such as your IP address, browser and device characteristics, operating
              system, language preferences, referring URLs, device name, country, location, information about how and
              when you use our Services, and other technical information. This information is primarily needed to
              maintain the security and operation of our Services, and for our internal analytics and reporting
              purposes.&nbsp;
            </p>
            <p className="mb-5">
              Like many businesses, we also collect information through cookies and similar technologies. More
              information about cookies can be found in our <span>Cookie Policy</span>.&nbsp;
            </p>
            <p className="mb-5">The information we collect includes:&nbsp;</p>
            <li className="mb-5">
              <strong>Log and Usage Data</strong>. Log and usage data is service-related, diagnostic, usage, and
              performance information our servers automatically collect when you access or use our Services and which we
              record in log files. Depending on how you interact with us, this log data may include your IP address,
              device information, browser type, and settings and information about your activity in the Services (such
              as the date/time stamps associated with your usage, pages and files viewed, searches, and other actions
              you take such as which features you use), device event information (such as system activity and error
              reports).&nbsp;
            </li>

            <li className="mb-5">
              <strong>Device Data</strong>. We collect device data such as information about your computer, phone,
              tablet, or other device you use to access the Services. Depending on the device used, this device data may
              include information such as your IP address (or proxy server), device and application identification
              numbers, location, browser type, hardware model, operating system, and system configuration
              information.&nbsp;
            </li>
            <li className="mb-5">
              <strong>Location Data</strong>. We use technologies to imprecisely collect geolocation data that tells us
              your current location (based on your IP address). You can opt out of allowing us to collect this
              information by refusing access to the information.&nbsp;
            </li>

            <h2 className="text-2xl mb-2 mt-10">2. HOW DO WE PROCESS YOUR INFORMATION?</h2>

            <p className="mb-5">
              <strong>In Short</strong>:{' '}
              <em>
                We process your information to provide, improve, and administer our Services, communicate with you, for
                security and fraud prevention, and to comply with law. We may also process your information for other
                purposes with your consent.&nbsp;
              </em>
            </p>
            <p className="mb-5">
              We process your personal information for a variety of reasons, depending on how you interact with our
              Services, including:&nbsp;
            </p>
            <li className="mb-5">
              <strong>To send administrative information to you</strong>. We may process your information to send you
              details about our Services, changes to our terms and policies, and other similar information.&nbsp;
            </li>
            <li className="mb-5">
              <strong>To request feedback. </strong>We may process your information when necessary to request feedback
              and to contact you about your use of our Services.
            </li>
            <li className="mb-5">
              <strong>To send you marketing and promotional communications</strong>. We may process the personal
              information you send to us for our marketing purposes, if this is in accordance with your marketing
              preferences. You can opt out of our marketing emails at any time. For more information, see &quot;WHAT ARE
              YOUR PRIVACY RIGHTS?&quot; below).&nbsp;
            </li>
            <li className="mb-5">
              <strong>To protect our Services. </strong>We may process your information as part of our efforts to keep
              our Services safe and secure, including fraud monitoring and prevention.&nbsp;
            </li>
            <li className="mb-5">
              <strong>To identify usage trends</strong>. We may process information about how you use our Services to
              better understand how they are being used so we can improve them.&nbsp;
            </li>
            <li className="mb-5">
              <strong>To save or protect an individual&apos;21qw2wq133üpßs vital interest. </strong>We may process your
              information when necessary to save or protect an individual&rsquo;s vital interest, such as to prevent
              harm.&nbsp;
            </li>
            <h2 className="text-2xl mb-2 mt-10">3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?</h2>
            <p className="mb-5">
              <strong>In Short</strong>:{' '}
              <em>
                We only process your personal information when we believe it is necessary and we have a valid legal
                reason (i.e. legal basis) to do so under applicable law, like with your consent, to comply with laws, to
                provide you with services to enter into or fulfill our contractual obligations, to protect your rights,
                or to fulfill our legitimate business interests.&nbsp;
              </em>
            </p>
            <p className="mb-5">
              <span>
                <strong>If you are located in the EU or UK, this section applies to you.</strong>
              </span>
            </p>
            <p className="mb-5">
              The General Data Protection Regulation (GDPR) and UK GDPR require us to explain the valid legal bases we
              rely on in order to process your personal information. As such, we may rely on the following legal bases
              to process your personal information:&nbsp;
            </p>
            <li className="mb-5">
              <strong>Consent</strong>. We may process your information if you have given us permission (i.e., consent)
              to use your personal information for a specific purpose. You can withdraw your consent at any time. Click{' '}
              <span>here</span>to learn more.&nbsp;
            </li>
            <li className="mb-5">
              <strong>Performance of a Contract. </strong>We may process your personal information when we believe it is
              necessary to fulfill our contractual obligations to you, including providing our Services or at your
              request prior to entering into a contract with you.&nbsp;
            </li>
            <li className="mb-5">
              <strong>Legitimate Interests. </strong>We may process your information when we believe it is reasonably
              necessary to achieve our legitimate business interests and those interests do not outweigh your interests
              and fundamental rights and freedoms. For example, we may process your personal information for some of the
              purposes described in order to:&nbsp;
            </li>
            <li className="mb-5"> Send users information about special offers and discounts on our Services</li>
            <li className="mb-5">
              Analyze how our Services are used so we can improve them to engage and retain users&nbsp;
            </li>
            <li className="mb-5"> Diagnose problems and/or prevent fraudulent activities&nbsp;</li>
            <li className="mb-5">Understand how our users use our Services so we can improve user experience&nbsp;</li>
            <li className="mb-5">
              <strong>Legal Obligations. </strong>We may process your information where we believe it is necessary for
              compliance with our legal obligations, such as to cooperate with a law enforcement body or regulatory
              agency, exercise or defend our legal rights, or disclose your information as evidence in litigation in
              which we are involved.&nbsp;
            </li>
            <li className="mb-5">
              <strong>Vital Interests. </strong>We may process your information where we believe it is necessary to
              protect your vital interests or the vital interests of a third party, such as situations involving
              potential threats to the safety of any person.&nbsp;
            </li>
            <h2 className="text-2xl mb-2 mt-10">4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</h2>
            <p className="mb-5">
              <strong>In Short</strong>:{' '}
              <em>
                We may share information in specific situations described in this section and/or with the following
                third parties.&nbsp;
              </em>
            </p>
            <p className="mb-5">We may need to share your personal information in the following situations:&nbsp;</p>
            <p className="mb-5">
              <strong>Business Transfers</strong>. We may share or transfer your information in connection with, or
              during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion
              of our business to another company.&nbsp;
            </p>
            <h2 className="text-2xl mb-2 mt-10">5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h2>
            <p className="mb-5">
              <strong>In Short</strong>:{' '}
              <em>We may use cookies and other tracking technologies to collect and store your information.&nbsp;</em>
            </p>
            <p className="mb-5">
              We may use cookies and similar tracking technologies to access or store information.&nbsp;
            </p>
            <p className="mb-5">
              Specific information about how we use such technologies and how you can refuse certain cookies is set out
              in our <span>Cookie Policy</span>
              <span>.</span>
            </p>
            <h2 className="text-2xl mb-2 mt-10">6. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</h2>
            <p className="mb-5">INTERNATIONALLY?&nbsp;</p>
            <p className="mb-5">
              <strong>In Short: </strong>
              <em>We may transfer, store, and process your information in countries other than your own.&nbsp;</em>
            </p>
            <p className="mb-5">
              Our servers are located in the US and Europe (Germany). If you are accessing our Services please be aware
              that your information may be transferred to, stored, and processed by us in our facilities and by those
              third parties with whom we may share your personal information in other countries.&nbsp;
            </p>
            <p className="mb-5">
              If you are a resident in the European Economic Area (EEA) or United Kingdom (UK), then these countries may
              not necessarily have data protection laws or other similar laws as comprehensive as those in your country.
              However, we will then take all necessary measures to protect your personal information in accordance with
              this privacy notice and applicable law.&nbsp;
            </p>
            <h2 className="text-2xl mb-2 mt-10">7. HOW LONG DO WE KEEP YOUR INFORMATION? </h2>
            <p className="mb-5">
              <strong>In Short</strong>:{' '}
              <em>
                We keep your information for as long as necessary to fulfill the purposes outlined in this privacy
                notice unless otherwise required by law.&nbsp;
              </em>
            </p>
            <p className="mb-5">
              We will only keep your personal information for as long as it is necessary for the purposes set out in
              this privacy notice, unless a longer retention period is required or permitted by law (such as tax,
              accounting, or other legal requirements).&nbsp;
            </p>
            <p className="mb-5">
              When we have no ongoing legitimate business need to process your personal information, we will either
              delete or anonymize such information, or, if this is not possible (for example, because your personal
              information has been stored in backup archives), then we will securely store your personal information and
              isolate it from any further processing until deletion is possible.&nbsp;
            </p>
            <h2 className="text-2xl mb-2 mt-10">8. HOW DO WE KEEP YOUR INFORMATION SAFE?</h2>
            <p className="mb-5">
              <strong>In Short</strong>:{' '}
              <em>
                We aim to protect your personal information through a system of organizational and technical security
                measures.&nbsp;
              </em>
            </p>
            <p className="mb-5">
              We have implemented appropriate and reasonable technical and organizational security measures designed to
              protect the security of any personal information we process. However, despite our safeguards and efforts
              to secure your information, no electronic transmission over the Internet or information storage technology
              can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or
              other unauthorized third parties will not be able to defeat our security and improperly collect, access,
              steal, or modify your information. Although we will do our best to protect your personal information,
            </p>
            <p className="mb-5">
              transmission of personal information to and from our Services is at your own risk. You should only access
              the Services within a secure environment.&nbsp;
            </p>

            <h2 className="text-2xl mb-2 mt-10">9. DO WE COLLECT INFORMATION FROM MINORS?</h2>
            <p className="mb-5">
              In Short: We do not knowingly collect data from or market to children under 18 years of age.&nbsp;.We do
              not knowingly solicit data from or market to children under 18 years of age. By using the Services, you
              represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to
              such minor dependent&rsquo;s use of the Services. If we learn that personal information from users less
              than 18 years of age has been collected, we will deactivate the account and take reasonable measures to
              promptly delete such data from our records. If you become aware of any data we may have collected from
              children under age 18, please contact us at <span>privacy@arrakis.finance.</span>
            </p>
            <h2 className="text-2xl mb-2 mt-10">10. WHAT ARE YOUR PRIVACY RIGHTS? </h2>
            <p className="mb-5">
              <strong>In Short</strong>:{' '}
              <em>
                In some regions, such as the European Economic Area (EEA), United Kingdom (UK), and Canada, and/or
                Switzerland, you have rights that allow you greater access to and control over your personal
                information. You may review, change, or terminate your account at any time.&nbsp;
              </em>
            </p>
            <p className="mb-5">
              In some regions (like the EEA, UK, and Canada and/or Switzerland), you have certain rights under
              applicable data protection laws. These may include the right (i) to request access and obtain a copy of
              your personal information, (ii) to request rectification or erasure; (iii) to restrict the processing of
              your personal information; and (iv) if applicable, to data portability. In certain circumstances, you may
              also have the right to object to the processing of your personal information. You can make such a request
              by contacting us by using the contact details provided in the section &quot;HOW CAN YOU CONTACT US ABOUT
              THIS NOTICE?&quot; below.&nbsp;
            </p>
            <p className="mb-5">
              We will consider and act upon any request in accordance with applicable data protection laws.&nbsp;
            </p>
            <p className="mb-5">
              If you are located in the EEA or UK and you believe we are unlawfully processing your personal
              information, you also have the right to complain to your local data protection supervisory authority. You
              can find their contact details here:&nbsp;
            </p>
            <p className="mb-5">
              <span>https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm</span>
            </p>
            <p className="mb-5">
              If you are located in Switzerland, the contact details for the data protection authorities are available
              here: <span>https://www.edoeb.admin.ch/edoeb/en/home.html</span>
              .&nbsp;
            </p>
            <p className="mb-5">
              <span>
                <strong>Withdrawing your consent</strong>
              </span>
              <span>:</span> If we are relying on your consent to process your personal information, which may be
              express and/or implied consent depending on the applicable law, you have the right to withdraw your
              consent at any time. You can withdraw your consent at any time by contacting us by using the contact
              details provided in the section &quot;HOW CAN YOU CONTACT US ABOUT THIS NOTICE?&quot; below.
            </p>
            <p className="mb-5">
              However, please note that this will not affect the lawfulness of the processing before its withdrawal nor,
              when applicable law allows, will it affect the processing of your personal information conducted in
              reliance on lawful processing grounds other than consent.&nbsp;
            </p>
            <p className="mb-5">
              <span>
                <strong>Opting out of marketing and promotional communications</strong>
              </span>
              : You can unsubscribe from our marketing and promotional communications at any time by clicking on the
              unsubscribe link in the emails that we send, or by contacting us using the details provided in the section
              &quot;HOW CAN YOU CONTACT US ABOUT THIS NOTICE?&quot; below. You will then be removed from the marketing
              lists. However, we may still communicate with you &mdash; for example, to&nbsp;
            </p>
            <p className="mb-5">
              send you service-related messages that are necessary for the administration and use of your account, to
              respond to service requests, or for other non-marketing purposes.&nbsp;
            </p>
            <p className="mb-5">
              <span>
                <strong>Cookies and similar technologies</strong>
              </span>
              <span>:</span> Most Web browsers are set to accept cookies by default. If you prefer, you can usually
              choose to set your browser to remove cookies and to reject cookies. If you choose to remove cookies or
              reject cookies, this could affect certain features or services of our Services.&nbsp;
            </p>
            <p className="mb-5">
              If you have any questions or comments about your privacy rights, you may email us at{' '}
              <span>privacy@arrakis.finance.</span>
            </p>
            <h2 className="text-2xl mb-2 mt-10">11. CONTROLS FOR DO-NOT-TRACK FEATURES</h2>
            <p className="mb-5">
              Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track
              (&quot;DNT&quot;) feature or setting you can activate to signal your privacy preference not to have data
              about your online browsing activities monitored and collected. At this stage no uniform technology
              standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently
              respond to DNT browser signals or any other mechanism that automatically communicates your choice not to
              be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will
              inform you about that practice in a revised version of this privacy notice.&nbsp;
            </p>

            <h2 className="text-2xl mb-2 mt-10">12. DO WE MAKE UPDATES TO THIS NOTICE?</h2>
            <p className="mb-5">
              In short: Yes, we will update this notice as necessary to stay compliant with relevant laws. We may update
              this privacy notice from time to time. The updated version will be indicated by an updated
              &quot;Revised&quot; date and the updated version will be effective as soon as it is accessible. If we make
              material changes to this privacy notice, we may notify you either by prominently posting a notice of such
              changes or by directly sending you a notification. We encourage you to review this privacy notice
              frequently to be informed of how we are protecting your information.
            </p>

            <h2 className="text-2xl mb-2 mt-10">13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h2>
            <p className="mb-5">
              If you have questions or comments about this notice, you may email us at <span>privacy@arrakis.finance</span>or
              by post to:&nbsp;
            </p>
            <p className="mb-5">Spacing Guildl GmbH&nbsp;</p>
            <p className="mb-5">Chamer Fussweg 11&nbsp;</p>
            <p className="mb-5">6300 Zug&nbsp;</p>
            <p className="mb-5">Schweiz&nbsp;</p>
            <p className="mb-5">Switzerland&nbsp;</p>

            <h2 className="text-2xl mb-2 mt-10">
              14. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
            </h2>
            <p className="mb-5">
              Based on the applicable laws of your country, you may have the right to request access to the personal
              information we collect from you, change that information, or delete it. To request to review, update, or
              delete your personal information, please email us at <span>privacy@arrakis.finance.</span>
            </p>
            <p className="mb-5">COOKIE POLICY&nbsp;</p>
            <p className="mb-5">Last updated: 22nd of March 2023</p>
            <p className="mb-5">
              This Cookie Policy explains how Spacing Guild GmbH (&quot;Company&quot;, &quot;we&quot;, &quot;us&quot;,
              and &quot;our&quot;) uses cookies and similar technologies to recognize you when you visit our websites at
              https://beta.arrakis.finance/vaults, (&quot;Websites&quot;). It explains what these technologies are and
              why we use them, as well as your rights to control our use of them.&nbsp;
            </p>
            <p className="mb-5">
              In some cases we may use cookies to collect personal information, or that becomes personal information if
              we combine it with other information.&nbsp;
            </p>
            <p className="mb-5">What are cookies?&nbsp;</p>
            <p className="mb-5">
              Cookies are small data files that are placed on your computer or mobile device when you visit a website.
              Cookies are widely used by website owners in order to make their websites work, or to work more
              efficiently, as well as to provide reporting information.&nbsp;
            </p>
            <p className="mb-5">
              Cookies set by the website owner (in this case, Spacing Guild GmbH) are called &quot;first party
              cookies&quot;. Cookies set by parties other than the website owner are called &quot;third party
              cookies&quot;. Third party cookies enable third party features or functionality to be provided on or
              through the website (e.g. analytics). The parties that set these third party cookies can recognize your
              computer both when it visits the website in question and also when it visits certain other websites.
            </p>
            <p className="mb-5">Why do we use cookies?&nbsp;</p>
            <p className="mb-5">
              We use first and third party cookies for several reasons. Some cookies are required for technical reasons
              in order for our Websites to operate, and we refer to these as &quot;essential&quot; or &quot;strictly
              necessary&quot; cookies. Other cookies also enable us to track and target the interests of our users to
              enhance the experience on our Online Properties. Third parties serve cookies through our Websites for
              analytics purposes.&nbsp;
            </p>
            <p className="mb-5">
              The specific types of first and third party cookies served through our Websites and the purposes they
              perform are described below (please note that the specific cookies served may vary depending on the
              specific Online Properties you visit):&nbsp;
            </p>
            <li className="mb-5">
              <strong>Google Analytics </strong>sets a cookie to provide insights regarding our websites&rsquo;
              performance, such as page views, conversion rates, device information, visitor IP addresses and referral
              sites.&nbsp;
            </li>
            <li className="mb-5">
              <strong>SmartLook </strong>sets cookies to identify your session and provide insights regarding your usage
              of our websites, such as page views, user interface interactions, conversion rates, device information and
              imprecise geographic location.&nbsp;
            </li>
            <p className="mb-5">How can I control cookies?&nbsp;</p>
            <p className="mb-5">
              You have the right to decide whether to accept or reject cookies. You can exercise your cookie rights by
              setting your preferences in the Cookie Consent Manager. The Cookie Consent Manager allows you to select
              which categories of cookies you accept or reject. Essential cookies cannot be rejected as they are
              strictly necessary to provide you with services.&nbsp;
            </p>
            <p className="mb-5">
              The Cookie Consent Manager can be found in the notification banner and on our website. If you choose to
              reject cookies, you may still use our website though your access to some functionality and areas of our
              website may be restricted.&nbsp;
            </p>
            <p className="mb-5">
              You may also set or amend your web browser controls to accept or refuse cookies. As the means by which you
              can refuse cookies through your web browser controls vary from browser-to-browser, you should visit your
              browser&apos;s help menu for more information.&nbsp;
            </p>
            <p className="mb-5">What about other tracking technologies, like web beacons?&nbsp;</p>
            <p className="mb-5">
              Cookies are not the only way to recognize or track visitors to a website. We may use other, similar
              technologies from time to time, like web beacons (sometimes called &quot;tracking pixels&quot; or
              &quot;clear gifs&quot;). These are tiny graphics files that contain a unique identifier that enable us to
              recognize when someone has visited our Websites or opened an e-mail including them.&nbsp;
            </p>
            <p className="mb-5">
              This allows us, for example, to monitor the traffic patterns of users from one page within a website to
              another, to deliver or communicate with cookies, to understand whether you have come to the website from
              an online advertisement displayed on a third-party website, to improve site performance, and to measure
              the success of e-mail marketing campaigns. In
            </p>
            <p className="mb-5">
              many instances, these technologies are reliant on cookies to function properly, and so declining cookies
              will impair their functioning.&nbsp;
            </p>
            <p className="mb-5">How often will you update this Cookie Policy?&nbsp;</p>
            <p className="mb-5">
              We may update this Cookie Policy from time to time in order to reflect, for example, changes to the
              cookies we use or for other operational, legal or regulatory reasons. Please therefore re-visit this
              Cookie Policy regularly to stay informed about our use of cookies and related technologies.&nbsp;
            </p>
            <p className="mb-5">The date at the top of this Cookie Policy indicates when it was last updated.</p>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}

export default PrivacyPolicy;
