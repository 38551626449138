import IconRight from '/assets/images/icon-right.svg';

interface DashboardLinkProps {
  vaultId: string;
}
function DashboardLink({ vaultId }: DashboardLinkProps) {
  return (
    <div className="flex flex-col sm:flex-row items-left sm:items-center gap-1">
      <div className="text-vault-gray-hover text-[16px] tracking-[-.01em]">Analytics:</div>

      <a
        target="_blank"
        rel="noopener noreferrer"
        href={`https://dashboard.arrakis.finance/?&vault=${vaultId}`}
        className="text-vault-light-gray text-[16px]  tracking-[-.01em] flex items-center gap-2"
      >
        See More <img src={IconRight} alt="check-icon" />
      </a>
    </div>
  );
}

export default DashboardLink;
